import { Fragment } from "react";
import { NextPage } from "next";
import { ErrorProps } from "next/error";

//components
import MetaDataComponent from "./components/MetaDataComponent";
import ErrorPage from "src/components/error-page";

const NotFoundPage: NextPage<ErrorProps> = (p) => {
  const message = p.title || "Page not Found";
  return (
    <Fragment>
      <MetaDataComponent />
      <ErrorPage message={message} status={p.statusCode} timestamp={new Date().toUTCString()} />
    </Fragment>
  );
};

export default NotFoundPage;
